.ecurie-active-page {
    width: 100%;
    background-color: white;
}

.header-image {
    position: relative;
    width: 100%;
    height: 40vh; /* Hauteur augmentée */
}

.header-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-text {
    position: absolute;
    bottom: 20px; /* Déplace le rectangle vers le bas */
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.8); /* Fond blanc avec transparence */
    padding: 20px;
    text-align: center;
    border-radius: 5px;
}

.header-text h1 {
    margin: 0;
    color: #00008B; /* Bleu */
}

.description {
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
}

.description p {
    margin: 0;
    color: #50504e; /* Gris */
    font-size: 0.8em; /* Réduction de la taille de la police */
}

.section {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}

.text-left .text {
    order: 1;
}

.text-left .image {
    order: 2;
}

.text-right .text {
    order: 2;
}

.text-right .image {
    order: 1;
}

.text, .image {
    flex: 1;
    padding: 20px;
}

.text h2 {
    color: #00008B; /* Bleu */
}

.text p {
    color: #50504e; /* Gris */
}

.image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
