/* src/components/Header.css */
body {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
}

.top-bar {
    background-color: #f1f1f1;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #333;
}

.top-bar .social-icons {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.top-bar .social-icons a {
    margin-left: 10px;
    color: #003366;
    text-decoration: none;
    transition: color 0.3s ease;
}

.top-bar .social-icons a:hover {
    color: #87CEEB;
}

.top-bar .social-icons a i {
    font-size: 18px;
}

.top-bar .contact-info {
    display: flex;
    align-items: center;
    color: #003366;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    color: #003366;
    position: relative;
}

.logo img {
    max-height: 50px;
}

nav {
    flex-grow: 1;
    text-align: center;
}

nav a {
    margin: 0 15px;
    color: #003366;
    text-decoration: none;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #87CEEB;
}

.search-container {
    display: flex;
    align-items: center;
    position: relative;
}

.search-container input[type="text"] {
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #ccc;
    border-radius: 25px;
    width: 200px;
    transition: width 0.4s ease-in-out;
}

.search-container input[type="text"]:focus {
    width: 300px;
}

.search-container .search-icon {
    position: absolute;
    left: 10px;
    color: #ccc;
    font-size: 16px;
}

.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
    margin-left: 20px;
}

.hamburger-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.hamburger-menu a {
    padding: 10px 20px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    nav {
        display: none;
    }
    .hamburger {
        display: block;
    }
    .header {
        justify-content: space-between;
        padding: 10px;
    }
    .logo {
        flex: 1;
    }
    .search-container {
        flex: 2;
        order: 1;
        margin-left: 10px;
    }
    .hamburger {
        order: 2;
        margin-right: 10px;
    }
    .header .logo, .header .search-container, .header .hamburger {
        margin: 5px 0;
    }
    .hamburger-menu {
        width: 100%;
        top: 100px;
    }
}

/* Highlight style for search results */
.highlight {
    background-color: rgb(255, 255, 255); /* You can adjust this color as needed */
}
