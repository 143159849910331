.installations-page {
    width: 100%;
    background-color: white;
  }
  
  .header-image {
    position: relative;
    width: 100%;
    height: 40vh; /* Hauteur augmentée */
  }
  
  .header-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .header-text {
    position: absolute;
    bottom: 0px; /* Déplace le rectangle vers le bas */
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.8); /* Fond blanc avec transparence */
    padding: 20px;
    text-align: center;
    border-radius: 5px;
  }
  
  .header-text h1 {
    margin: 0;
    color: #00008B;
  }
  
  .description {
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .description p {
    margin: 0;
    color: #333;
    font-size: 1.2em;
  }
  
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .text {
    flex: 1;
    margin-bottom: 20px;
  }
  
  .image {
    width: 100%;
  }
  
  .image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Styles pour les écrans plus larges */
  @media (min-width: 768px) {
    .section {
      flex-direction: row;
    }
  
    .text-left .text {
      order: 1;
    }
  
    .text-left .image {
      order: 2;
    }
  
    .text-right .text {
      order: 2;
    }
  
    .text-right .image {
      order: 1;
    }

    /* Styles spécifiques pour les sections Parc d'obstacles, Douche extérieure et Localisation */
    #Parc\\ d\'Obstacles .section,
    #Douche\\ Extérieure .section,
    #Localisation .section {
      flex-direction: column;
    }

    #Parc\\ d\'Obstacles .text,
    #Douche\\ Extérieure .text,
    #Localisation .text {
      order: 1;
    }

    #Parc\\ d\'Obstacles .image,
    #Douche\\ Extérieure .image,
    #Localisation .image {
      order: 2;
    }
  }
