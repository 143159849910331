body {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
}

.top-bar {
    background-color: #f1f1f1;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #333;
}

.top-bar .social-icons {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.top-bar .social-icons a {
    margin-left: 10px;
    color: #003366;
    text-decoration: none;
    transition: color 0.3s ease;
}

.top-bar .social-icons a:hover {
    color: #87CEEB;
}

.top-bar .social-icons a i {
    font-size: 18px;
}

.top-bar .contact-info {
    display: flex;
    align-items: center;
    color: #003366;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    color: #003366;
    position: relative;
}

.logo img {
    max-height: 50px;
    width: auto;
    display: block;
}

nav {
    flex-grow: 1;
    text-align: center;
}

nav a {
    margin: 0 15px;
    color: #003366;
    text-decoration: none;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #87CEEB;
}

.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
    margin-left: 20px;
}

.hamburger-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    align-items: center;
}

.hamburger-menu a {
    padding: 10px 20px;
    white-space: nowrap;
    text-align: center;
}

@media (max-width: 768px) {
    nav {
        display: none;
    }
    .hamburger {
        display: block;
        width: 100px; /* Ajoute une largeur fixe pour le bouton hamburger */
        text-align: left; /* Aligne le texte à gauche */
        padding-left: 20px; /* Ajoute du padding à gauche */
    }
    .header {
        justify-content: center; /* Aligner les éléments au centre */
        padding: 10px;
    }
    .logo {
        margin-right: 0; /* Supprimer la marge pour centrer */
    }
    .hamburger {
        margin-left: 0; /* Supprimer la marge pour centrer */
    }
    .header .logo, .header .hamburger {
        margin: 20px;
    }
    .hamburger-menu {
        width: 100%;
        top: 60px;
    }
    .hamburger-menu.show {
        display: flex;
    }
}
