body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  padding: 20px;
  color: #00008B;
  display: flex;
  justify-content: center;
}

.section-3 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.row {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container,
.container-right {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
}

h1 {
  color: #00008B;
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: left;
}

h1 span {
  color: #00BFFF;
}

ul {
  list-style-type: none;
  padding: 0;
  color: #34495e;
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
  display: inline-block;
  text-align: start;
}

ul li {
  margin-bottom: 8px;
  position: relative;
}

.tarifs {
  color: #34495e;
  text-align: left;
  font-size: 14px;
  display: inline-block;
}

.carousel {
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.carousel-inner {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  flex: 0 0 100%;
  transition: transform 0.5s ease;
}

.carousel-inner img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 4px;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

.btn-en-savoir-plus {
  border: 2px solid #00008B;
  border-radius: 8px;
  background-color: #ffffff;
  color: #00008B;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  margin: 10px auto 0;
  transition: color 0.3s;
}

.btn-en-savoir-plus:hover {
  color: #00BFFF;
}

@media (max-width: 768px) {
  .row {
    justify-content: center;
  }

  .container,
  .container-right {
    width: 80%; /* Réduire encore la largeur des conteneurs */
    margin-left: auto;
    margin-right: auto;
  }

  .col-md-4 {
    flex: 0 0 100%;
    max-width: 80%;
    margin-bottom: 20px;
  }

  .carousel-inner img {
    max-height: 500px; /* Augmenter la hauteur pour des images plus grandes sur les téléphones */
  }

  .btn-en-savoir-plus {
    float: none;
    display: block;
    margin: 10px auto 0;
  }

  .tarifs {
    margin-left: 0;
  }
}
