.section-5 {
    padding: 20px;
    text-align: center;
}

.gallery-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.blue-text {
    color: #87CEEB; /* Bleu ciel */
}

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 images par ligne */
    gap: 15px;
    justify-items: center;
}

.gallery-item {
    width: 100%;
    height: 300px; /* Hauteur fixe pour toutes les images */
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre par défaut */
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Les images remplissent le conteneur tout en gardant leur proportion */
    transition: transform 0.3s ease;
}

.gallery-item:hover {
    transform: scale(1.05); /* Légère augmentation de la taille */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Augmentation de l'ombre */
}

.gallery-item:hover img {
    transform: rotate(2deg); /* Légère rotation */
}

/* Responsif pour les petits écrans */
@media (max-width: 768px) {
    .gallery {
        grid-template-columns: 1fr; /* 1 image par ligne pour les écrans de téléphone */
    }
}
