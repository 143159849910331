@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.footer {
    background-color: #003366;
    color: white;
    padding: 20px 0;
    text-align: center;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px;
}

.footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #66ccff; /* Light blue color */
}

.footer-section p, .footer-section a {
    font-size: 14px;
    color: #ccc;
    text-decoration: none;
    margin-bottom: 5px;
    display: block;
}

.footer-section a:hover {
    color: #66ccff; /* Light blue color on hover */
}

.footer-bottom {
    background-color: #002244;
    color: #ccc;
    padding: 10px 0;
    font-size: 12px;
}

.footer-nav ul {
    list-style: none;
    padding: 0;
}

.footer-nav li {
    margin-bottom: 5px;
}
