body {
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
}

.section {
    width: 90%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.text-section {
    width: 50%;
}

.text-section h1 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #00008B;
}

.text-section h1 span:first-child {
    color: #87CEEB;
}

.text-section h1 span:last-child {
    color: #00008B;
}

.text-section p {
    font-size: 1em;
    margin-bottom: 20px;
    color: #00008B;
}

.text-section button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: white;
    color: #007BFF;
    border: 2px solid #007BFF;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 20px;
    border-radius: 10px;
}

.text-section button:hover {
    background-color: #87CEEB; /* Bleu ciel */
    color: white;
}

.image-section {
    width: 45%;
}

.image-section img {
    width: 100%;
    border-radius: 10px;
}

.banner {
    width: 75%;
    padding: 20px;
    background-color: white;
    color: #00008B;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.banner-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
}

.banner-section {
    width: 32%;
    display: flex;
    flex-direction: column;
}

.banner h2 {
    font-size: 1.2em;
    margin-bottom: 15px;
    position: relative;
    padding-left: 35px;
}

.banner h2::before {
    content: url('https://img.icons8.com/ios-filled/25/87CEEB/horse.png');
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
}

.banner ul {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
    flex-grow: 1;
}

.banner ul li {
    font-size: 1em;
    margin-bottom: 5px;
    color: #00008B;
    position: relative;
    padding-left: 20px;
    transition: color 0.3s;
}

.banner ul li::before {
    content: '-';
    position: absolute;
    left: 0;
    color: #87CEEB;
}

.banner ul li:hover {
    color: #87CEEB;
    cursor: pointer;
}

/* Prevent default link styling */
a:link, a:visited {
    text-decoration: none;
    color: inherit;
}

a:hover {
    color: #87CEEB; /* Bleu ciel */
}

@media (max-width: 768px) {
    .section {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: auto; /* Centrer la section horizontalement */
        margin-right: 0.5cm; /* Ajouter un espace de 0,5 cm à droite */
    }

    .text-section {
        order: 1;
        width: 100%;
        margin-bottom: 20px;
    }

    .image-section {
        order: 2;
        width: 100%;
        text-align: center;
    }

    .banner {
        padding: 10px;
    }

    .banner-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .banner-section {
        width: 100%;
        margin-bottom: 20px;
    }

    .banner h2 {
        display: block;
        width: 100%;
        padding-left: 35px;
        padding-bottom: 10px;
        border-bottom: 1px solid #87CEEB;
        margin-bottom: 10px;
    }

    .banner ul {
        margin-left: 0;
    }

    .banner ul li {
        padding-left: 10px;
    }
}
