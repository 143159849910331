/* src/components/Section3.css */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 80%;
    max-width: 1200px;
  }
  
  .team-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #003366 50%, #66ccff 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .team-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
    transition: transform 0.3s, box-shadow 0.3s, border-radius 0.3s;
  }
  
  .team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }
  
  .team-member img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 15px;
  }
  
  .team-member p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .container {
      width: 100%;
    }
  
    .team-container {
      flex-direction: column;
      gap: 40px;
      width: 100%;
    }
  
    .team-member {
      width: 100%;
    }
  
    .team-member img {
      height: 400px;
    }
  }
  